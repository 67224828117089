/* styles in place - recheck inline styles:todo(CY)*/

/* index.css */
@tailwind base;
@layer base {
  img {
    @apply inline;
  }
}
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 10px;
  ;
}
::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  border-radius: 10px;
}

@keyframes spinners-react-infinity {
  0% {
    stroke-dasharray: 1, 347;
    stroke-dashoffset: 75;
  }
  25%, 75% {
    stroke-dasharray: 17, 330;
  }
  50% {
    stroke-dasharray: 1, 347;
  }
  100% {
    stroke-dasharray: 1, 347;
    stroke-dashoffset: 423;
  }
}

.no-data-svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-svg {
  height: 27vh;
  width: 53vw;
}

/* div#root {
  /* position: absolute; */
  /* top: 1vh; */
  /* margin-left: -1vw;
} */ 

div#container {
  margin-top: 29vh;
  margin-left: 6vw;
}

.nav-bar {
  width: 5vw;
  margin-left: 2vw;
}

nav a:hover {
  @apply bg-purple-100; /* Change to your desired active color */
}

nav a.active {
  @apply text-purple-900 !important; /* Change to your desired active color */

}

.logo-card {
  background-color: #ffffff;
  height: 13vh;
  border-radius: 1vh;
  padding: 1.5vh;
  margin-bottom: 1vh;
  box-shadow: 0 1vh 1.5vh rgba(0, 0, 0, 0.1);
}

.progress {
  height: 3.9vh;
}

.nav-card {
  background-color: #ffffff;
  height: 83vh;
  border-radius: 1vh;
  margin-bottom: 1vh;
  box-shadow: 0 1vh 1.5vh rgba(0, 0, 0, 0.1);
}

.nav-menu-items.navbar-items {
  @apply list-none ml-0;
}

.nav-text > a {
  @apply flex flex-col;
}

.nav-text > a > span {
  @apply text-xs ml-0 flex justify-center; /* Adjusted font size */
}

li.nav-text {
  @apply inline-block w-full text-center;
}



.nav-text > a > svg {
  @apply self-center text-sm w-8 h-8; /* Adjusted icon size */
}

li.nav-text:hover {
  @apply bg-purple-200; /* Change to your desired hover color */
}


.title {
  font-family: sans-serif;
  color: #3D3D3D;
}

.subtitle {
  color: #757476;
}

.clientTitle {
  @apply text-3xl font-bold mt-0 mb-1;
}

.clientSubtitle {
  @apply text-base text-gray-700 font-normal mt-0 mb-0 italic; /* Adjust font size, color, and margin as needed */
}

@media screen and (max-width: 768px) {
  /* .title {
    @apply text-lg;
  } */
  .subtitle {
    @apply text-base;
  }
}

@media screen and (max-width: 576px) {
  /* .title {
    @apply text-base;
  } */
  .subtitle {
    @apply text-sm;
  }
}
/* Styles for the parent container */
.flex.items-center.bg-gray-200.rounded-md.justify-between {
  width: 12vw;
}

/* Styles for the selected option */
.text-blue-600.font-bold.italic.pl-2 {
  font-size: 0.8rem;
  font-style: italic;
  color: #1B96F7;
  font-weight: bold;
  margin-left: 0.6vw;
}


/* Styles for the chart buttons */
.chartButton {
  width: 1.01rem;
  height: 1.7vh;
  background-color: #E8E8E8;
  border-color: #E8E8E8;
  padding: 0;
  border: none;
  /* margin: 0; */
  font-size: 2vh;
  /* padding-bottom: 0vh; */
  /* color: blue; */
}

/* .chartCard.rounded-lg.shadow-md.p-4 {
  height: 49vh;
}.chartCard.rounded-lg.shadow-md.p-4 {
  height: 49vh;
} */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

